import * as React from "react"
import { navigate } from "gatsby"

import "./404.css"

function NotFoundPage() {
  const [timeLeft, setTime] = React.useState(6)
  const counter = setInterval(() => {
    //If all the time has passed
    if(timeLeft <= 1) {
      clearInterval(counter)
      navigate('/')
    }
    setTime(timeLeft - 1)
  }, 1000)

  return (
    <main id="notfound-container">
      <title>¯\_(ツ)_/¯</title>
      <h1>404</h1>
      <p>
        This page does not exist!
      </p>
      <p>
        Well, there is only the main page to be honest...
      </p>
      <p>
        See you there in <span>{timeLeft}</span>.
      </p>
    </main>
  )
}

export default NotFoundPage
